<template>
  <div class="pa-10">
    <v-btn
      class="mb-14"
      color="info"
      @click="show = !show"
    >
      Toggle Tooltips
    </v-btn>
    <v-row class="py-4">
      <v-col cols="2">
        Mailing Statuses:
      </v-col>
      <v-col
        v-for="item in mailingItemStatuses"
        :key="item.value"
        cols="1"
      >
        <v-tooltip
          v-model="show"
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
            >
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
              <span v-if="item.value === 1">/</span>
              <v-icon
                v-if="item.value === 1"
                :color="item.color"
              >
                {{ item.icon }}-sync
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="py-4">
      <v-col cols="2">
        Target Audience Statuses:
      </v-col>
      <v-col
        v-for="item in taStatuses"
        :key="item.value"
        cols="1"
      >
        <v-tooltip
          v-model="show"
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
            >
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mailingItemStatus } from '@/shared/models/mailing'
import { taStatus } from '@/shared/models/noww'

export default {
  data () {
    return {
      show: true
    }
  },

  computed: {
    mailingItemStatuses () {
      return mailingItemStatus
    },
    taStatuses () {
      return [
        { text: 'Not selected', value: 0, color: 'grey', icon: 'mdi-checkbox-blank-circle-outline' },
        ...taStatus
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
