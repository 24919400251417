var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-10" },
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-14",
          attrs: { color: "info" },
          on: {
            click: function($event) {
              _vm.show = !_vm.show
            }
          }
        },
        [_vm._v("\n    Toggle Tooltips\n  ")]
      ),
      _c(
        "v-row",
        { staticClass: "py-4" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _vm._v("\n      Mailing Statuses:\n    ")
          ]),
          _vm._l(_vm.mailingItemStatuses, function(item) {
            return _c(
              "v-col",
              { key: item.value, attrs: { cols: "1" } },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: item.color } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.icon) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  item.value === 1
                                    ? _c("span", [_vm._v("/")])
                                    : _vm._e(),
                                  item.value === 1
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: item.color } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(item.icon) +
                                              "-sync\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.show,
                      callback: function($$v) {
                        _vm.show = $$v
                      },
                      expression: "show"
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(item.text))])]
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-row",
        { staticClass: "py-4" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _vm._v("\n      Target Audience Statuses:\n    ")
          ]),
          _vm._l(_vm.taStatuses, function(item) {
            return _c(
              "v-col",
              { key: item.value, attrs: { cols: "1" } },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: item.color } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.icon) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.show,
                      callback: function($$v) {
                        _vm.show = $$v
                      },
                      expression: "show"
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(item.text))])]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }